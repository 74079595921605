import React from "react"
import {Link as GatsbyLink } from "gatsby";

import  { useState, useEffect } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from 'gatsby-link'
// import Hero1 from "../components/Hero/Hero1"
// import Recognition from "../components/Recognition"
// import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
// import Card1 from "../components/Card/Card1"
// import { StaticImage } from "gatsby-plugin-image"
// import Cta1 from "../components/Cta/Cta1"
import { graphql } from "gatsby"

export const pageQuery = graphql`
  query NewQuery {
    wpcontent {
      posts {
        edges {
          node {
            slug
            id
            title

            author {
              node {
                slug
                id
              }
            }
            content
            featuredImage {
              node {
                slug
                id
                guid
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
 
   const posts = data.wpcontent.posts.edges
   const [filteredResults, setFilteredResults] = useState<any>([]);
    const [searchInput, setSearchInput] = useState('');
  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
        const filteredData = posts.filter(({node: post}) => {
          console.log(post)
            return post.title.toLowerCase().includes(searchInput.toLowerCase())
        })
        console.log(filteredData)
        setFilteredResults(filteredData)
    }
    else{
        setFilteredResults(posts)
    }
}
  return (
    <Layout>
      <SEO title="Home" />
      

      <div className="progress"></div>


      
      <Container>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-10 lg:place-items-center lg:mt-20  md:p-[1rem]  lg:h-[30rem]">
          <div>
            <h1 className="mt-4 fi lg:text-[56px]  md:text-[41px]">Hyperautomation</h1>
            <h1 className="mt-4 fi fh fhl  lg:text-[56px]  md:text-[41px] leading-[70px] ">
              Blog
            </h1>
            <p className="mt-4 fhl text-[18px] leading-[22px]">
              one small step for you one giant leap for your organisation.
            </p>
            <br />
            <input
              type="text"
              className=" fhl ht1 form-control block w-full font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded ht1 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Enter Work Email-id"
            />
            <br />
            <br />

            <button className=" fhl bg-transparent bg-black text-white-700 font-semibold hover:text-white blogbtn border border-black-500 hover:border-transparent rounded">
              Email Me Latest updates
            </button>
          </div>

          <div className="items-end ">
            <img
              className="blogimg1"
              src="/images/people.png"
              alt="Sunset in the mountains"
            />
          </div>
        </div>
        <br />


        <div className="grid lg:grid-cols-2 sm:grid-cols-2  gap-14 lg:mt-21 lg:mt-[10rem] p-[1rem] place-items-center">
          <div className="items-end ">
            <img
              className="blogimg "
              src="/images/mask.png"
              alt="Sunset in the mountains"
            />
          </div>
          <div className="items-end">
            <h1 className="blogft">
              6 Key AI Trends in Hospitality – Where the bots are winning
            </h1>
            <p className="text-gray-700 text-base mtr1">
              Some quick example text to build on the card title and make up the
              bulk of the card's content. make up the bulk of the card's
              content.
            </p>
            <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
              Read More
            </button>
          </div>
        </div>
        <br></br>

        <div className="search float-right bml lg:mt-[5rem]">
          <span className="fa fa-qrcode category"></span>
          <span className="fa fa-search"></span>
          <input  onChange={(e) => searchItems(e.target.value)} className="bloginput" placeholder="Search term" />
        </div>
        <div className=" lg:mt-[15rem]  grid grid-cols-1 tablet:grid-cols-2  mt-22  mtr10 md:grid-cols-2 lg:grid-cols-4 gap21 mlr mb-10">
        {searchInput.length > 1 ? (
                    filteredResults.map(({node: post}) => {
                        return (
                          
                          <div
                          key={post.id}
                          className="rounded overflow-hidden shadow-lg blogcard"
                        >
                          {post.featuredImage && (
                            <img
                              className="w-full blogcardimg"
                              src={post.featuredImage.node.guid}
                              alt="Sunset in the mountains"
                            />
                          )}
                       
                          <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2 p3">{post.title}</div>
                            <p className="text-gray-700 p1 text-base"
                            dangerouslySetInnerHTML={{__html: post.content}} />
                           
                            <button className="bg-transparent bg-red-500 blogcardbtn text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
                              <span className="pcartbtntext">Read More</span>
                              
                            </button>
                           
                          </div>
                    
                        </div>
                        
                        )
                    })
                ):(
          
            posts.map(({node: post}) => (
              <div
                key={post.id}
                className="ht5 rounded overflow-hidden shadow-lg blogcard"
              >
                {post.featuredImage && (
                  <img
                    className="w-full blogcardimg"
                    src={post.featuredImage.node.guid}
                    alt="Sunset in the mountains"
                  />
                )}
                <div className="px-6 py-4  ">
                  <div className="font-bold text-xl mb-2 p3">{post.title}</div>
                  <p className="text-gray-700 p1 text-base"
                  dangerouslySetInnerHTML={{__html: post.content}} />

                  
                  <Link   key={post.slug} to={`/read-blog/${post.id}`} >
                  <button className="bg-transparent bg-red-500 blogcardbtn text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
                    <span className="pcartbtntext">Read More</span>
                  </button>
                  </Link>
                </div>
              </div>
            )))}


        </div>
      </Container>
   
      <div className=" grid bgrose  lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1  gap-10 place-items-center mt-5  h-[21rem]">
        <div className="items-end blog3 ">
          <h1 className="mt-4 bg7 clrred"> Product Updates And </h1>
          <h1 className="mt-4 bg7 clrred"> Insights Straight </h1>
          <h1 className="mt-4 bg7 clrred">To Your Box </h1>
        </div>

        <div>
          <div className="relative text-gray-700 m18rem mrl7">
            <input
              className="w-full h-[4rem] h-15 pl-5  br bg-white placeholder-gray-600 border rounded-lg focus:shadow-outline"
              type="text"
              placeholder="Enter Your Email"
            />
            <button className="absolute btn3 btnred  m-0  flex items-center pl45 font-bold text-white  w-40 ">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <br />
      <br />

      { }
    </Layout>
  )
}

export default IndexPage
